var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create_new_inquiry"},[_c('validation-observer',{ref:"personalInfoForm"},[_c('b-card',{attrs:{"title":"Personal Information"}},[_c('b-form',[_c('b-row',{staticClass:"personal_information_wrapper"},[_c('b-col',{class:{'afterUploadImg':_vm.previewImage.length!=0},attrs:{"md":"1"}},[_c('validation-provider',{attrs:{"name":"image file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.previewImage.length!=0)?_c('img',{staticClass:"profile_img",attrs:{"src":_vm.previewImage,"alt":"profile image","width":"160px","height":"145px"}}):_vm._e(),_c('b-form-file',{attrs:{"id":"imagePicker","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":"image/*","state":errors.length > 0 ? false:null},on:{"change":_vm.checkFile}}),_c('img',{staticClass:"upload_img_inner",attrs:{"src":require("@/assets/images/icons/onedrive.png")}}),(_vm.uploadingImage)?_c('b-spinner',{staticStyle:{"position":"absolute","z-index":"999","top":"50px","left":"40%"},attrs:{"variant":"primary","large":""}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.previewImage),expression:"previewImage"},{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"type":"text"},domProps:{"value":(_vm.previewImage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.previewImage=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"11"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"First Name"}},[_c('validation-provider',{attrs:{"name":"first name","rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Last Name"}},[_c('validation-provider',{attrs:{"name":"last name","rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){_vm.email=_vm.email.toLowerCase().trim()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(!_vm.checkEmail)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_c('small',{staticClass:"text-danger"},[_vm._v("This email is already user.")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"position-relative cls_phoneno_wrapper",attrs:{"label":"Mobile Number"}},[_c('span',{staticClass:"phoneno_prefix"},[_c('phoneCountryCodeVue',{on:{"country-selected":_vm.onCountrySelected}})],1),_c('validation-provider',{attrs:{"name":"mobile no.","rules":{required:true,integer:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"mobile no.","state":errors.length > 0 ? false:null,"placeholder":"Enter number"},on:{"blur":function($event){_vm.mobileNumber=_vm.mobileNumber.trim()},"keypress":_vm.isOnlyInteger,"input":_vm.checkValidPhoneNumber},model:{value:(_vm.mobileNumber),callback:function ($$v) {_vm.mobileNumber=$$v},expression:"mobileNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(errors.length == 0 && !_vm.validNumberOrNot)?_c('small',{staticClass:"text-danger"},[_vm._v("Please provide valid phone number.")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Date of Birth"}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"example1",staticClass:"test input-date",attrs:{"type":"date","placeholder":"YYYY-MM-DD","show-decade-nav":"","data-date-inline-picker":"true","max":_vm.getMaxDate(),"state":errors.length > 0 ? false:null},on:{"change":function($event){return _vm.changeBirthDate()}},model:{value:(_vm.dob),callback:function ($$v) {_vm.dob=$$v},expression:"dob"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Gender"}},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","flex-wrap":"wrap","height":"38px"}},[_c('b-form-radio',{attrs:{"plain":"","name":"gender","value":"Male"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_vm._v(" Male ")]),_c('b-form-radio',{attrs:{"plain":"","name":"gender","value":"Female"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_vm._v(" Female ")]),_c('b-form-radio',{attrs:{"plain":"","name":"gender","value":"Other"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_vm._v(" Other ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Intake"}},[_c('validation-provider',{attrs:{"name":"intake","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.intakeOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},model:{value:(_vm.intake),callback:function ($$v) {_vm.intake=$$v},expression:"intake"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Year"}},[_c('validation-provider',{attrs:{"name":"year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.yearOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"title":"Communication Address"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Address Line 1"}},[_c('validation-provider',{attrs:{"name":"address line 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){_vm.addressLine1=_vm.addressLine1.trim()}},model:{value:(_vm.addressLine1),callback:function ($$v) {_vm.addressLine1=$$v},expression:"addressLine1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Address Line 2"}},[_c('validation-provider',{attrs:{"name":"address line 2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){_vm.addressLine2=_vm.addressLine2.trim()}},model:{value:(_vm.addressLine2),callback:function ($$v) {_vm.addressLine2=$$v},expression:"addressLine2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Country"}},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.countryOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},on:{"change":function($event){return _vm.getStates()}},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"State"}},[_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.country,"options":_vm.stateOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},on:{"change":function($event){return _vm.getCities()}},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"City"}},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.state,"options":_vm.cityOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Pincode"}},[_c('validation-provider',{attrs:{"name":"pincode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Pincode"},on:{"blur":function($event){_vm.pincode=_vm.pincode.trim()}},model:{value:(_vm.pincode),callback:function ($$v) {_vm.pincode=$$v},expression:"pincode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body edit_student_main card_body_bt0"},[_c('h4',{staticClass:"card-title"},[_vm._v(" Education Details ")]),_c('b-form',_vm._l((_vm.educationDetails),function(data,index){return _c('b-row',{key:'educationDetails'+index,style:(index!=_vm.educationDetails.length-1?'border-bottom:1px solid #cfcfcf;':'')},[(index<2)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Education"}},[_c('validation-provider',{attrs:{"name":"education","vid":'education'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":true,"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){data.education=data.education.trim()}},model:{value:(data.education),callback:function ($$v) {_vm.$set(data, "education", $$v)},expression:"data.education"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(index>=2)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Degree"}},[_c('validation-provider',{attrs:{"name":"degree","vid":'degree'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.educationOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},on:{"change":function (e){_vm.educationFiledSet(e,index,'edId','education',1)}},model:{value:(data.edId),callback:function ($$v) {_vm.$set(data, "edId", $$v)},expression:"data.edId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(index>=2)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Branch/Stream/Field"}},[_c('validation-provider',{attrs:{"name":"branch/stream/field","vid":'branch/stream/field1'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.branchFieldOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},on:{"change":function (e){_vm.educationFiledSet(e,index,'bsfId','branchOrStreamOrFieldName',4)}},model:{value:(data.bsfId),callback:function ($$v) {_vm.$set(data, "bsfId", $$v)},expression:"data.bsfId"}}),(data.bsfId !== 'others')?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"branch/stream/field","vid":'branch/stream/field2'+index,"rules":data.bsfId == 'others'?'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(data.bsfId == 'others')?_c('b-form-input',{staticStyle:{"margin-top":"5px"},attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){data.branchOrStreamOrFieldName=data.branchOrStreamOrFieldName.trim()}},model:{value:(data.branchOrStreamOrFieldName),callback:function ($$v) {_vm.$set(data, "branchOrStreamOrFieldName", $$v)},expression:"data.branchOrStreamOrFieldName"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(index<2)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"School/ College Name"}},[_c('validation-provider',{attrs:{"name":"school/college name","vid":'school/college name1'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){data.schoolOrCollegeName=data.schoolOrCollegeName.trim()}},model:{value:(data.schoolOrCollegeName),callback:function ($$v) {_vm.$set(data, "schoolOrCollegeName", $$v)},expression:"data.schoolOrCollegeName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"School/ College Name"}},[_c('validation-provider',{attrs:{"name":"school/college name","vid":'school/college name2'+index,"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.schoolOrCollegeNameOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},on:{"change":function (e){_vm.educationFiledSet(e,index,'scId','schoolOrCollegeName',2)}},model:{value:(data.scId),callback:function ($$v) {_vm.$set(data, "scId", $$v)},expression:"data.scId"}}),(data.scId !== 'others')?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"school/college name","vid":'school/college name3'+index,"rules":data.scId == 'others'?'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(data.scId == 'others')?_c('b-form-input',{staticStyle:{"margin-top":"5px"},attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){data.schoolOrCollegeName=data.schoolOrCollegeName.trim()}},model:{value:(data.schoolOrCollegeName),callback:function ($$v) {_vm.$set(data, "schoolOrCollegeName", $$v)},expression:"data.schoolOrCollegeName"}}):_vm._e(),(data.scId == 'others')?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),(index<2)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Board/ University Name"}},[_c('validation-provider',{attrs:{"name":"board/university name","vid":'board/university name1'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){data.boardUniversityName=data.boardUniversityName.trim()}},model:{value:(data.boardUniversityName),callback:function ($$v) {_vm.$set(data, "boardUniversityName", $$v)},expression:"data.boardUniversityName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Board/ University Name"}},[_c('validation-provider',{attrs:{"name":"board/university name","vid":'board/university name2'+index,"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.boardUniversityNameOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},on:{"change":function (e){_vm.educationFiledSet(e,index,'bouId','boardUniversityName',3)}},model:{value:(data.bouId),callback:function ($$v) {_vm.$set(data, "bouId", $$v)},expression:"data.bouId"}}),(data.bouId !== 'others')?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"board/university name","vid":'board/university name3'+index,"rules":data.bouId == 'others'?'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(data.bouId == 'others')?_c('b-form-input',{staticStyle:{"margin-top":"5px"},attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){data.boardUniversityName=data.boardUniversityName.trim()}},model:{value:(data.boardUniversityName),callback:function ($$v) {_vm.$set(data, "boardUniversityName", $$v)},expression:"data.boardUniversityName"}}):_vm._e(),(data.bouId == 'others')?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"cgpi/percentage","vid":'cgpi/percentage'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","flex-wrap":"wrap","height":"38px","margin-top":"30px"}},[_c('b-form-radio',{attrs:{"plain":"","value":"Percentage"},model:{value:(data.cgpa_percentage),callback:function ($$v) {_vm.$set(data, "cgpa_percentage", $$v)},expression:"data.cgpa_percentage"}},[_vm._v(" Percentage ")]),_c('b-form-radio',{attrs:{"plain":"","value":"CGPI"},model:{value:(data.cgpa_percentage),callback:function ($$v) {_vm.$set(data, "cgpa_percentage", $$v)},expression:"data.cgpa_percentage"}},[_vm._v(" CGPI ")]),_c('b-form-radio',{attrs:{"plain":"","value":"Grade"},model:{value:(data.cgpa_percentage),callback:function ($$v) {_vm.$set(data, "cgpa_percentage", $$v)},expression:"data.cgpa_percentage"}},[_vm._v(" Grade ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(data.cgpa_percentage!='Grade')?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":data.cgpa_percentage}},[_c('validation-provider',{attrs:{"name":data.cgpa_percentage,"vid":data.cgpa_percentage+index,"rules":data.cgpa_percentage!='CGPI'?'required|max_value:100':'required|max_value:10'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Number"},on:{"blur":function($event){data.cgpa_percentageValue=data.cgpa_percentageValue.trim()},"keypress":_vm.isOnlyDecimal},model:{value:(data.cgpa_percentageValue),callback:function ($$v) {_vm.$set(data, "cgpa_percentageValue", $$v)},expression:"data.cgpa_percentageValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":data.cgpa_percentage}},[_c('validation-provider',{attrs:{"name":data.cgpa_percentage,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.gradeOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},model:{value:(data.cgpa_percentageValue),callback:function ($$v) {_vm.$set(data, "cgpa_percentageValue", $$v)},expression:"data.cgpa_percentageValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Year of Passing"}},[_c('validation-provider',{attrs:{"name":"year of passing","vid":'year of passing'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.passingYearOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},model:{value:(data.yearOfPassing),callback:function ($$v) {_vm.$set(data, "yearOfPassing", $$v)},expression:"data.yearOfPassing"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticStyle:{"text-align":"right"}},[(index>1)?_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.removeEducationRow(index)}}},[_vm._v(" Remove Education ")]):_vm._e()],1)],1)}),1)],1),_c('div',{staticClass:"card-body add_edu_link",staticStyle:{"border-top":"1px solid #cfcfcf"}},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.addNewEducationRow()}}},[_vm._v("Add a Education")])])])]),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"card_body_bt0",attrs:{"title":"Language"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"German Language"}},[_c('validation-provider',{attrs:{"name":"german language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","flex-wrap":"wrap","height":"38px"}},[_c('b-form-radio',{attrs:{"plain":"","name":"german language","value":"None"},model:{value:(_vm.germanLanguage),callback:function ($$v) {_vm.germanLanguage=$$v},expression:"germanLanguage"}},[_vm._v(" None ")]),_c('b-form-radio',{attrs:{"plain":"","name":"german language","value":"A1"},model:{value:(_vm.germanLanguage),callback:function ($$v) {_vm.germanLanguage=$$v},expression:"germanLanguage"}},[_vm._v(" A1 ")]),_c('b-form-radio',{attrs:{"plain":"","name":"german language","value":"A2"},model:{value:(_vm.germanLanguage),callback:function ($$v) {_vm.germanLanguage=$$v},expression:"germanLanguage"}},[_vm._v(" A2 ")]),_c('b-form-radio',{attrs:{"plain":"","name":"german language","value":"B1"},model:{value:(_vm.germanLanguage),callback:function ($$v) {_vm.germanLanguage=$$v},expression:"germanLanguage"}},[_vm._v(" B1 ")]),_c('b-form-radio',{attrs:{"plain":"","name":"german language","value":"B2"},model:{value:(_vm.germanLanguage),callback:function ($$v) {_vm.germanLanguage=$$v},expression:"germanLanguage"}},[_vm._v(" B2 ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"card_body_bt0",attrs:{"title":"Exams"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Exams"}},[_c('validation-provider',{attrs:{"name":"exams"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","multiple":"","options":_vm.examOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},model:{value:(_vm.exams),callback:function ($$v) {_vm.exams=$$v},expression:"exams"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_vm._l((_vm.exams),function(data,index){return _c('b-col',{key:'band scores'+index,attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":data.title}},[_c('validation-provider',{attrs:{"name":data.title,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Number"},on:{"blur":function($event){data.score=data.score.trim()}},model:{value:(data.score),callback:function ($$v) {_vm.$set(data, "score", $$v)},expression:"data.score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)})],2)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"title":"Student Interest"}},[_c('b-form-group',{attrs:{"label":"Area of Interest"}},[_c('b-form-input',{attrs:{"placeholder":"Enter Text"},on:{"blur":function($event){_vm.areaOfInterest=_vm.areaOfInterest.trim()}},model:{value:(_vm.areaOfInterest),callback:function ($$v) {_vm.areaOfInterest=$$v},expression:"areaOfInterest"}})],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[(false)?_c('b-card',{attrs:{"title":"Field of Interest"}},[_c('b-form',[_vm._l((_vm.fieldOfInterests),function(data,index){return _c('b-row',{key:'field of interest'+index,staticStyle:{"margin-top":"10px"},style:(_vm.fieldOfInterests.length>0?'border-bottom:1px solid #cfcfcf':'')},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Select Field"}},[_c('validation-provider',{attrs:{"name":"select field","rules":"","vid":'select field'+index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.fieldOptions,"state":errors.length > 0 ? false:null,"placeholder":"Select"},on:{"input":function($event){return _vm.filterCourse(index,data)}},model:{value:(data.field),callback:function ($$v) {_vm.$set(data, "field", $$v)},expression:"data.field"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Select Course"}},[_c('validation-provider',{attrs:{"name":"select course","rules":!data.field?'':'required',"vid":'select course'+index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!data.field,"options":data.courseOption,"state":errors.length > 0 ? false:null,"placeholder":"Select"},model:{value:(data.course),callback:function ($$v) {_vm.$set(data, "course", $$v)},expression:"data.course"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"add_edu_link",staticStyle:{"font-weight":"600","margin-bottom":"10px","width":"100%","display":"flex","justify-content":"space-between","padding":"0px 14px"}},[(index==_vm.fieldOfInterests.length-1)?_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.addNewRow()}}},[_vm._v(" Add New Row ")]):_vm._e(),(index!=0)?_c('a',{staticClass:"add_edu_link_remove",staticStyle:{"text-decoration":"underline","color":"#ff0b0b"},style:(index!=_vm.fieldOfInterests.length-1?'text-align: right;display: block;width: 100%;':''),on:{"click":function($event){$event.preventDefault();return _vm.removeRow(index)}}},[_vm._v(" Remove ")]):_vm._e()])],1)}),_c('div',{staticClass:"add_edu_link",staticStyle:{"font-weight":"600","margin-bottom":"10px","width":"100%","display":"flex","justify-content":"space-between","padding":"0px 14px"}},[(_vm.fieldOfInterests.length==0)?_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.addNewRow()}}},[_vm._v(" Add New Row ")]):_vm._e()])],2)],1):_vm._e()],1)],1)],1),(false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{attrs:{"title":"Payment"}},[_c('b-form',_vm._l((_vm.payments),function(data,index){return _c('b-row',{key:'payments'+index},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Payment Received Date"}},[_c('validation-provider',{attrs:{"name":"payment received date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-date",attrs:{"id":"example-input","onkeydown":"return false","type":"date","placeholder":"YYYY-MM-DD","autocomplete":"off","show-decade-nav":"","state":errors.length > 0 ? false:null,"max":new Date()},model:{value:(data.paymentReceivedDate),callback:function ($$v) {_vm.$set(data, "paymentReceivedDate", $$v)},expression:"data.paymentReceivedDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Mode Of Payment"}},[_c('validation-provider',{attrs:{"name":"mode of payment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","flex-wrap":"wrap","height":"38px"}},[_c('b-form-radio',{attrs:{"plain":"","name":"mode of payment","value":"Cash"},model:{value:(data.modeOfPayment),callback:function ($$v) {_vm.$set(data, "modeOfPayment", $$v)},expression:"data.modeOfPayment"}},[_vm._v(" Cash ")]),_c('b-form-radio',{attrs:{"plain":"","name":"mode of payment","value":"Cheque"},model:{value:(data.modeOfPayment),callback:function ($$v) {_vm.$set(data, "modeOfPayment", $$v)},expression:"data.modeOfPayment"}},[_vm._v(" Cheque ")]),_c('b-form-radio',{attrs:{"plain":"","name":"mode of payment","value":"Other"},model:{value:(data.modeOfPayment),callback:function ($$v) {_vm.$set(data, "modeOfPayment", $$v)},expression:"data.modeOfPayment"}},[_vm._v(" Other ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(data.modeOfPayment == 'Cheque')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Bank Name"}},[_c('validation-provider',{attrs:{"name":"bank name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){data.bankName=data.bankName.trim()}},model:{value:(data.bankName),callback:function ($$v) {_vm.$set(data, "bankName", $$v)},expression:"data.bankName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(data.modeOfPayment == 'Cheque')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cheque No."}},[_c('validation-provider',{attrs:{"name":"cheque no.","rules":"required|integer|min:6|max:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"cheque no.","state":errors.length > 0 ? false:null,"placeholder":"Enter number"},on:{"blur":function($event){data.chequeNo=data.chequeNo.trim()},"keypress":_vm.isOnlyInteger},model:{value:(data.chequeNo),callback:function ($$v) {_vm.$set(data, "chequeNo", $$v)},expression:"data.chequeNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Rupees (INR)"}},[_c('validation-provider',{attrs:{"name":"rupees","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"rupees","state":errors.length > 0 ? false:null,"placeholder":"0.00"},on:{"blur":function($event){data.rupees=data.rupees.trim()},"keypress":_vm.isOnlyDecimal},model:{value:(data.rupees),callback:function ($$v) {_vm.$set(data, "rupees", $$v)},expression:"data.rupees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[(data.modeOfPayment=='Other')?_c('b-form-group',{attrs:{"label":"Remark"}},[_c('validation-provider',{attrs:{"name":"remark","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"name":"remark","state":errors.length > 0 ? false:null,"placeholder":"Enter Text"},on:{"blur":function($event){data.remark=data.remark.trim()}},model:{value:(data.remark),callback:function ($$v) {_vm.$set(data, "remark", $$v)},expression:"data.remark"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)],1)}),1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }